
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from 'next/head'
import { useRouter } from 'next/router'

import Button from 'components/button'

import { useTranslate } from 'hooks'

import { DEFAULT_SOCIAL_IMAGE } from 'constants/constants'

import css from './page-not-found.module.scss'

type PageNotFoundProps = {
  title?: string
  description?: string
  image?: string
}

const Page404 = ({ title = '', description = '', image = DEFAULT_SOCIAL_IMAGE }: PageNotFoundProps) => {
  const router = useRouter()
  const { t } = useTranslate('common')

  const goBack = () => {
    router.back()
  }

  return (
    <>
      <Head>
        <title>{title || t('not_found.title')}</title>
        <meta property="og:image" content={image} key="og:image" />
        <meta name="description" content={description} key="og:description" />
      </Head>
      <main className={css.container}>
        <section className={css.container}>
          <h1>404 - {t('not_found.title')}</h1>
          <Button onClick={goBack} label={t('not_found.go_back')} />
        </section>
      </main>
    </>
  )
}

export default Page404


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  